.navTree li {
 list-style: none !important;
}

.section-news #content-core {
    display: flex;
    flex-flow: row wrap;
}

.section-news article.tileItem {
    padding: 15px;
    color: #fff;
    height: 300px;
    margin-bottom: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: box-shadow 0.5s; /* Safari 3.1 to 6.0 */
    transition: box-shadow 0.5s;
    position: relative;
}

.section-news .news-item:hover article.tileItem {
    box-shadow: 4px 4px 4px #888;
}

.section-news .tileItem h2 a {
    color: #fff;
}

.section-news .tileItem h2 a:hover {
    border-bottom: none;
}

.section-news .tileItem .documentByLine {
   display: none;
}

.section-news .tileItem .tileFooter a {
   color: #fff;
}

.section-news .news-item a {
   color: #fff;
   text-decoration: none;
}
.section-news .pagination {
   margin-left: 25%;
   margin-right: auto
}

.ms-caption {
  top: 120px;
  left: 400px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 40px;
  padding-left: 40px !important;
  border-radius: 16px;
//   backdrop-filter: blur(10px);
}

.plone-breadcrumb {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 35px;
}
#breadcrumbs-you-are-here {
  display: none;
}

.portletNavigationTree .portletHeader a {
  font-size: 24px;
  border-bottom: 2px solid #337ab7;
}
.portletNavigationTree .portletHeader a:hover {
  text-decoration: none;
}
.portletNavigationTree .portletHeader {
  margin-bottom: 30px;
}

/* try without
.member-title h3 a {
  color: #666;
  text-decoration: none;
}
.member-title h3 a:hover {
  border-bottom: none;
}

*/
.casestudy-logo {
    width: 120px;
    min-height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    margin-top: 20px;
}

.casestudy-search-text {
    margin-right: 20px;
}

.casestudy-search-label {
   margin-left: 15px;
}

.casestudy-search-controls {
   margin-top: 20px;
   margin-bottom: 20px;
}
.search-controls {
   margin-top: 20px;
   margin-bottom: 20px;
   margin-left: 5px;
}

.cal_wkday {
  font-size: 12px !important;
}
.cal_date {
  border-radius: 3px !important;
}
.vevent span.cal_month {
  font-size: 14px !important;
}



/* material cards */
.md-card {
  color: #fff;
  padding: 5px;
  padding-top: 0px;
  margin-bottom: 10px;
}
.md-card a {
  color: #fff;
}
.mf-card a:hover {
  color: #fff;
}
.md-card .inner-container {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  transition: box-shadow 0.5s;
}
.md-card:hover .inner-container {
  box-shadow: 4px 4px 4px #888;
}
.md-card-bottom {
  position: absolute;
  bottom: 0;
  height: 100px;
  background-color: rgba(183, 178, 178, 0.6);
  left: 0;
  right: 0;
  padding-left: 10px;
  text-shadow: 1px 1px #555;
}

i.link-https { display: none !important; }


#portaltab-resources .submenu.dropdown-menu {
  left: auto;
  right: 0;
}

ul.configlets li {
    list-style: none;
}

#viewlet-social-like > div {
    float: right !important;
}

.portaltype-news-item .leadImage {
   float: right;
   padding: 10px;
}
.portaltype-news-item .leadImage img {
    width: 250px;
}
.portaltype-event .leadImage {
   float: right;
   padding: 10px;
}
.portaltype-event .leadImage img {
    width: 250px;
}
.plone-toolbar-left #edit-zone {
    z-index: 2000 !important;
}
#top-header {
    z-index: 1000 !important;
}

/* event styling */
.cal_date {
   box-shadow: none;
}
.vevent div.cal_date {
  background-color: #eff3ff !important;
}

.minicolors-theme-default .minicolors-input {
    width: 150px !important;
    height: 30px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-bottom: 15px !important;
}

div#join-group {
    margin-top: 20px;
    background: lightblue;
    border-radius: 3px;
    text-align: center;
    padding: 10px;
}

.member-details-website .glyphicon-globe {
    vertical-align: top;
    margin-top: 5px;
}

.member-details-website .detail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 500px;
    display: inline-block;
}

.project-title h3.title {
   margin-top: 0px !important;
}

.darken-news {
   background: rgba(0,0,0,0.5);
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   position: absolute;
}

.news-children {
   top: 16px;
   left: 16px;
   right: 16px;
   bottom: 16px;
   position: absolute;
overflow: hidden;
}

.subsection-program-communications #content-core table.listing td,
.subsection-program-communications #content-core table.listing th {
   display: none;
}
.subsection-program-communications #content-core table.listing td:nth-child(1),
.subsection-program-communications #content-core table.listing th:nth-child(1)  {
   display: block;
}


table.simpleborders th, td {
    padding: 5px !important;
    border: 1px solid #cccccc !important;
}

/** this works, once compiled **/
.section-toolbox #viewlet-above-content div.toolbox-section h1.title {

}


/* BEGIN PORTLET CALENDAR */
.portletCalendar {
    width: auto;
    margin: 1px 0 1em 0;
}

.portletCalendar dt {
    background-color: #eee;
    font-weight: bold;
    text-align: center;
    line-height: 1.6em;
    border-bottom: none;
}

.portletCalendar dd {
    margin: 0;
    padding: 0;
}

.portletCalendar dt a.calendarPrevious {
    float: left;
    padding: 0 1em;
    margin: 0 -.5em;
}

.portletCalendar dt a.calendarNext {
    float: right;
    padding: 0 1em;
    margin: 0 -.5em;
}

.portletCalendar a.calendarNext,.portletCalendar a.calendarPrevious{
    color:inherit;
}

.portletCalendar a, .portletCalendar a:focus,
.portletCalendar a, .portletCalendar a:hover,
.ploneCalendar a {
    text-decoration: none;
    border-bottom: none !important;
}

.ploneCalendar {
    border-spacing: 0;
    width:100%;
}

.ploneCalendar td {
    background-color: transparent;
    width: 14%;
    text-align: center;
    padding: 2px;
    border: 2px solid #eee;
    background-color: #eee;
}

.ploneCalendar .weekdays th {
    background-color: #eee;
    text-align: center;
    padding: 2px;
}

.ploneCalendar .event {
    background-color: #eee;
}

.ploneCalendar .todayevent {
    background-color: #eee;
    border: 2px solid #205C90;
}

.ploneCalendar .todaynoevent {
    border: 2px solid #205C90;
}

/* additional to sunburst */
.ploneCalendar td.today {
    background-color: white;
    border-color: #205C90;
}
.ploneCalendar td:hover { background-color: white; }
.ploneCalendar td a {
    display:inline-block;
    width:100%;
    height:100%;
}

/* grey out prev/next month incl. links and background */
.ploneCalendar td.cal_prev_month,
.ploneCalendar td.cal_next_month {
    opacity:0.4;
}

/* tooltip styling. by default the element to be styled is .tooltip  */
.pae_calendar_tooltip {
    display:none;
    background-color:white;
    border:1px solid black;
    border-radius:0.3em;
    box-shadow: 0.1em 0.1em 0.1em black;
    font-size:80%;
    padding:10px;
    margin:10px;
    max-width: 30em;
    z-index: 10;
}
.pae_calendar_tooltip a {
    color:black;
    display:block;
    width:100%;
}
.pae_calendar_tooltip a:hover { color:blue; }
.pae_calendar_tooltip a .title { font-weight:bold; }

/* END PORTLET CALENDAR */
/*===========================*/

/* fix layout issues for editing block pages */
.template-layout-manager #main-container .sidebar-offcanvas  {
    width: 0px!important;
    display:none
}

/* hide plone warnings from client */
.template-overview-controlpanel .portalMessage.warning { display: none }


/*========================*/
/* Accessibility updates  */
/*========================*/

@media (min-width: 1200px) {
    .container {
        width: 95%;
        max-width:1400px;
        font-size: 18px;
    }

}