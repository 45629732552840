/* NSW Landcare theme LESS */
body {
    color: #666;
    background-color: #fff;
    font-family: "Open Sans",Arial,sans-serif;
    font-size: 16px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.7em;
}

a {
    color: #337ab7;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.fixed-header {
	position: fixed;
	z-index: 1000;
}
.col-centered{
    float: none;
    margin: 0 auto;
}

.outer-wrapper {
	padding-top: 105px;
}

@TOP_HEADER_HEIGHT: 38px;

#top-header {
	background-color: #795548;
    top: 0px;
    right: 0px;
    left: 0px;
    height: @TOP_HEADER_HEIGHT;

    z-index: 100000;
    color: rgb(255, 255, 255);
    font-size: 12px;
    line-height: 13px;

    a {
        color: white;
        transition: background-color 0.4s, color 0.4s ease-in-out;
        display: block;
        height: @TOP_HEADER_HEIGHT;
        line-height: @TOP_HEADER_HEIGHT;
        padding: 0 8px;

        font-size: 14px;
        font-weight: 600;

        transition: background-color 0.3s;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            text-decoration: none;
        }
    }

    ol, ul {
        list-style: none;
        margin-bottom: 0 !important;
        padding: 0;

        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

// #top-header .container {
//     // padding-top: 1.5rem;
//     font-weight: 600;
// }
// #top-header a, #secondary-nav li li a{

// }


.link-external {
	display: none !important;
}

#portal-menu {
	background-color:rgba(255, 255, 255, 0.85);
    top: 38px;
    width: 100%;
    backdrop-filter: blur(5px);
}

#portal-menu .logo-container {
	float: left;
	width: 200px;
	padding-top: 10px;
    padding-bottom: 5px;
}

#portal-menu .nav-menu a {
    display: block;
    position: relative;
    color: rgba(0,0,0,.6);
    text-decoration: none;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    font-size: 16px;
    font-weight: 600;
}

#portal-logo img {
    max-width: 100%;
    max-height: 100%;
}

#portlet-prefs ul ul li a:before {
    margin-top: -1px !important;
    left: 30px !important;
}

#portaltab-index_html {
    display: none;
}

.navbar-header {
    display: none;
}

.navbar {
    --bs-navbar-background: transparent;
}

/* Plone nav menu */
.plone-navbar-nav li .submenu li {
	background-color:rgba(255, 255, 255, 1) !important;
    width: 100%;
}

#portal-globalnav-wrapper {
    margin-bottom: 0px !important;
    border: none !important;
    float: right;
}

#portal-menu .nav-menu a:hover {
	background-color: rgba(215, 225, 255, 0.8);
}

.group-header-wrapper {
    height: 350px;
    background-size: cover;
    background-position: center;
}

.group-header-block {
    padding-top: 143px;
}

.group-header-title {
    color: #fff;
    padding-top: 127px;
    text-shadow: 1px 1px #555;
}

.group-header-logo {
    width: 100%;
    height: 100%;
    border: 14px solid #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}


.group-header-logo-wrapper {
    float: left;
    margin-top: 50px;
    background-color: rgba(255, 255, 255, 1);
    width: 264px;
    height: 220px;
    margin-right: 55px;
    border-radius: 8px;
    padding: 20px;
}

@media (max-width: 500px) {
    .group-header-block {
        padding-top: 0;
    }

    .group-header-logo-wrapper {
        margin-top: 0;
        padding: 0;
        width: 124px;
        height: 115px;
    }
}

.group-header-link:hover {
    text-decoration: none;
}

.group-contact-header {
    color: #666;
    background-color: #eee;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 600;
}

.group-contact-details {
    padding: 10px;
    padding-left: 15px;
}

.group-contact-details-wrapper {
    border-radius: 4px;
    margin-bottom: 25px;
}

.group-contact-details .label {
    font-weight: bold;
    padding: 0;
    font-size: 14px;
}

.group-contact-details .detail {
    overflow: hidden;
    width: 235px;
    display: block;
    font-size: 14px;
}

.contact-details-person .person {
    font-size: 14px;
}

.group-contact-details .contact-details-email a,
.group-contact-details .contact-details-website a  {
    font-size: 14px !important;
}

.contact-details-email i { display: none; }
.always-show { display: inline-block !important; }
i.glyphicon-mailto:before { content: "\2709"; }

@media (min-width: 768px) {
    .navbar-nav>li>a {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}

.hiddenStructure { display: none; }

/* Hide type icons */
.icons-on .portlet.portletNavigationTree [class*=contenttype-],
.icons-on .portlet.group-nav [class*=contenttype-]
{
    background-image: none;
}

.portlet.portletNavigationTree
{
    margin-top: 0;
    margin-bottom: 20px;
    padding: 0;
    background: transparent;

    .navTree {
        padding-left: 0px;
    }

    dt.hiddenStructure
    {
        display: none;
    }

    img /* Hide type icons */
    {
        display: none;
    }

    .navTreeItem
    {
        position: relative;

        & > a
        {
            color: #666;

            &:hover {
                background-color: rgba(200, 200, 200, 0.6);
                text-decoration: none;
            }
        }
    }

    .navTreeCurrentItem:after
    {
        content: "";
        float: right;
        vertical-align: middle;
    }

    .navTreeCurrentItem {
        background-color: #e4eef0 !important;
        padding-left: 10px !important;
    }


    .navTreeLevel0
    {
        margin: 0;

        &> .navTreeItem > a
        {
            margin-bottom: 1px;
            border-bottom: 1px solid #ccc;

            &:before
            {
                position: relative;
                margin-left: 3px;
            }

            &.navTreeItemInPath
            {
                border-bottom: 0;
            }
        }
    }

    .navTreeLevel1
    {

        &> .navTreeItem > a
        {
            padding-left: 20px !important;
            margin-bottom: 1px;
            background-color: #eee;

            &:before
            {
                left: -11px;
            }
        }

        .navTree
        {
            margin-left: 0px;
            margin-right: -15px;
            background-color: #eee;

            .navTreeItem > a
            {
                padding-left: 15px !important;
            }

        }
    }

    .navTreeLevel2.navTree
    {
        margin-left: -15px;
        margin-bottom: 1px;

        &> .navTreeItem
        {
            &> a
            {
                padding-left: 30px !important;

                &:before
                {
                    left: 0px;
                }
            }

            > .navTree
            {
                padding-left: 30px;

                a:before
                {
                    left: -15px;
                }
            }
        }
    }

    li
    {
        margin: 0;

        a
        {
            line-height: 1em;
            display: block;
            border: 0;
            padding: 8px 15px;
            padding-left: 0px !important;
            color: #888;
        }

    }
}

.portlet.portletNavigationTree .static-section span.title {
    padding-left: 5px;
}
.portlet.portletNavigationTree .static-section {
    padding-left: 3px;
}

.portlet.portletNavigationTree li a {
    padding: 15px 0px;
    line-height: 1.2em;
    margin-bottom: 0px !important;
}
.portlet.portletNavigationTree li a:hover {
    background-color: #b7e5f8 !important;
}
.portlet.portletNavigationTree .navTreeCurrentItem {
    background-color: #b7e5f8 !important;
    padding-left: 10px !important;
}



#portal-column-one {
    padding-top: 20px;
}
// turn off contenttype icons for group navigation
body#visual-portal-wrapper.pat-plone .outer-wrapper #portal-column-one [class*="contenttype-"]:before {
    display: none;
}

/* footer */
#portal-footer-wrapper {
    background-color: #607d8b;
    margin-top: 60px;
    padding-top: 60px;
    color: #fff;
    padding-bottom: 20px;
}

#portal-footer-wrapper a {
    color: #fff;
}
#portal-footer-wrapper a:hover {
    color: #fff;
}

.footer-heading {
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
}

.footer-list {
    list-style: none;
    padding-left: 0;
}

/* news cards */
.news-filter-form {
    margin-bottom: 27px;
    margin-left: 15px;
}

.news-filter-form input {
    margin-left: 27px;
}

.news-card {
    color: #fff;
    padding: 5px;
    padding-top: 0px;
    margin-bottom: 10px;
}

.news-card a {
    color: #fff;
}
.news-card a:hover {
    color: #fff;
}

.bg-div {
    color: #fff;
    height: 300px;
    margin-bottom: 15px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    position: relative;
    background-size: cover;
}

.bg-darken {
    background: rgba(0,0,0,0.5);
    height: 100% !important;
    width: 100%;
    display: inline-block;
}

.bg-div:hover {
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    transform: scale(1.03)
}

.news-card .inner-container {
    height: 300px;
    padding: 16px;
    padding-top: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    transition: box-shadow 0.5s;

    position: relative;
}

.news-card:hover .inner-container {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.news-children {
    color: #fff;
}

.news-card-bottom {
    position: absolute;
    bottom: 0;
    height: 120px;
    background-color: rgba(40, 40, 40, 0.25);
    left: 0;
    right: 0;
    padding-left: 10px;
    text-shadow: 1px 1px #555;

    backdrop-filter: blur(3px);
}

.group-body-heading {
    padding-left: 15px;
}


.has-image {
    background: rgba(0, 0, 0, 0.45);
}

.bg-div {
    //height:100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    color: white;
    position: relative;
    margin-bottom: 27px;
}

.tileHeadline, .tileFooter, .tileBody, .tileDate {
    color: white;
}

.tileHeadline:hover {
    text-decoration: none;
    border-bottom: none;
}

.eventTitle {
    color: white;
    font-size: 20px;
    line-height: 1.5em;
}

.bg-darken {
    background-color: rgba(0,0,0,0.5);
    top:0;
    bottom:0;
    right:0;
    left:0;
    width:100%;
    height:100%;
    padding: 15px 15px 30px 15px;
    display:block;
    overflow: hidden;
    word-wrap:break-word;
    position: relative;
}

.bg-darken:hover {
    cursor: pointer;
}

.news-children {
    position: absolute;
    overflow: hidden;
    bottom: 35px;
}


/* some variables for dynamic line wrapping */
@news-para-font-size: 14px;
@news-para-line-height: 1.5;
@news-para-lines-to-show: 6;

@news-desc-font-size: 18px;
@news-desc-line-height: 1.6;
@news-desc-lines-to-show: 2;

.news-card-heading {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  height: @news-desc-font-size*@news-desc-line-height*@news-desc-lines-to-show; /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: @news-desc-font-size;
  line-height: @news-desc-line-height;
  -webkit-line-clamp: @news-desc-lines-to-show;
  line-clamp: @news-desc-lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;

  font-weight: bold;
}

.news-card-oneline {
    //text-overflow: ellipsis;
    //overflow: hidden;
    //white-space: nowrap;
    //width: 257px;
    display: block;
    line-height: 1.5;
}

.facebook-feed-portlet {
    margin-top: 40px;
}

/* nice colours to choose from */
.nice-colour-0 { background-color: #8bc34a; }
.nice-colour-1 { background-color: #ffc107; }
.nice-colour-2 { background-color: #009688; }
.nice-colour-3 { background-color: #5677fc; }
.nice-colour-4 { background-color: #673ab7; }
.nice-colour-5 { background-color: #03a9f4; }
.nice-colour-6 { background-color: #00bcd4; }
.nice-colour-7 { background-color: #9c27b0; }

.project-logo {
    width: 100%;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.member-logo {
    width: 100%;
    min-height: 93px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.member-block:last-child {
    border-bottom: none;
}

.member-block {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc;
}
.member-block:last-child {
    border-bottom: none;
}

.member-details {
    float: left;
    margin-right: 10px;
}

.project-title a {
    color: #666;
}
.project-title a:hover {
    color: #666;
}

.project-description {
  margin-bottom: 10px;
}

/* twitter portlet */

.tweet {
    padding: 15px;
    position: relative;
    background: rgba(51, 185, 255, 0.09);
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
    margin-bottom: 15px;
}

.tweet .post-content .author:before {
    content: "";
    background-image: url(../images/twitter.png);  // url(++theme++nswlandcare.theme/images/twitter.png);
    padding: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    padding-top: 5px;
    margin-right: 5px;
    display: inline-block;
}


.tweet .tweet-date {
    margin-top: 5px;
    font-weight: bold;
    font-size: 11px;
}

.tweet .actions .link-https {
    display: none !important;
}
.tweet .actions {
    padding-left: 40px;
    font-size: 14px;
}
.tweet .actions a {
    padding-right: 10px;
}
.link-https {
    display: none;
}

/* fix portlet action alignment */
form.portlet-action {
    display: inline-block;
}
span.managedPortletActions {
    display: block;
}

/* agreement files fix */
.file-link p {
width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#portal-globalnav-wrapper > .container { width: auto; }

#portal-globalnav li.active a.dropdown-toggle {
    background-color: rgba(215, 225, 255, 0.8);
}

.documentDescription {
    margin-bottom: 40px;
}

/* default img fixes */
img {
    max-width: 100%;
    width: auto\9;
    height: auto;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
}

.event.summary {
    margin-left: 30px;
    margin-bottom: 20px !important;
    box-shadow: none !important;
}

/* fix footer padding */
#portal-footer-wrapper #portal-footer .row .col-xs-12:first-child {
    padding-right: 0px;
    padding-left: 0px;
}

/* add new item buttons */
.group-add-item {
    position: absolute;
    right: 10px;
    top: 8px;
}

.group-edit-btn {
    position: absolute;
    top: -10px;
    right: 25px;
}

// @media (min-width: 992px) {
//     body.col-one.col-content #portal-column-one {
//         order: 1;
//         flex: 0 0 auto;
//         width: 100%;
//         padding-left: 0;
//         padding-right: 0;
//     }

//     body.col-one.col-two.col-content #portal-column-one {
//         width: 100%;
//         padding-left: 0;
//         padding-right: 0;
//     }

//     body.col-one.col-two.col-content #portal-column-two {
//         width: 100%;
//         padding-left: 0;
//         padding-right: 0;
//     }
// }

.card.portletNavigationTree {
    border: 0;
}

.lead {
    font-weight: bold;
}

// .template-event_listing {

//     article#content {
//         position: relative;
//     }

//     nav {
//         font-size: 14px;
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
// }

#portal-breadcrumbs {
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 0;

    .breadcrumb {
        margin-bottom: 0;
    }
}

.markspeciallinks__icon {
    display: none;
}

.navTree .plone-icon {
    display: none;
}

body.portaltype-block-page.template-layout-manager {
    section#portal-column-content {
        width: auto!important;
    }
}