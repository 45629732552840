/* Base LESS imports */

@import "barceloneta.css";

// standard Plone mixin/misc styling
// @import "mixin.forms.plone.less";
// @import "mixin.prefixes.plone.less";
// @import "mixin.borderradius.plone.less";
// @import "mixin.buttons.plone.less";
// @import "mixin.tabfocus.plone.less";
// @import "pagination.plone.less";
// @import "pickadate.plone.less";
// @import "print.plone.less";
// @import "search.plone.less";
// @import "sitemap.plone.less";
// @import "sortable.plone.less";
// @import "states.plone.less";
// @import "tables.plone.less";
// @import "tablesorter.plone.less";
// @import "tags.plone.less";
// @import "thumbs.plone.less";
// @import "tree.plone.less";

// // standard Plone elements styling
@import "variables.plone.less";
// @import "buttons.plone.less";
// @import "alerts.plone.less";
// @import "accessibility.plone.less";
// @import "behaviors.plone.less";
// @import "breadcrumbs.plone.less";
// @import "code.plone.less";
// @import "contents.plone.less";
// @import "controlpanels.plone.less";
// @import "dropzone.plone.less";
// @import "event.plone.less";
// @import "forms.plone.less";
// @import "formtabbing.plone.less";
// @import "loginform.plone.less";

// // type styling from Plone
@import "type.plone.less";
// // some standard Plone view styling
// @import "views.plone.less";

// NSW Landcare main
@import "nswlandcare.less";
// NSW Landcare main responsiveness
@import "responsive.less";


@import "event.less";

@import "glyphicons.less";

// Copied custom.less from Plone 5.2's ++plone++custom/custom.less
@import "custom.less";
@isPlone: false;