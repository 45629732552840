/* NSW Landcare responsive LESS */

/* MD Bootstrap */
@media all and (max-width: 991px) {
	
}

/* SM Bootstrap */
@media all and (max-width: 768px) {
	
	.tweet .actions {
	    padding-left: 0px;
	}
}

/* XS Bootstrap */
@media all and (max-width: 480px) {
	.outer-wrapper {
		padding-top: 0px;
	}

	.fixed-header {
		position: initial;
	}
	.navbar-toggle {
	    background-color: #e5e5e5 !important;
	    border-color: #b2b2b2 !important;

	    .icon-bar {
		    border-bottom: 1px solid #bbb;
	    }
	}
	#portal-globalnav > li {
	    border-bottom: 1px solid #ccc;
	}
	#portal-globalnav > li:last-child {
		border-bottom: none;
	}
	#portal-menu .nav-menu {
		float: none;
	}
}