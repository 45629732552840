/*===========================*/
/* BEGIN COMMON EVENT STYLES */
/* Stolen from old plone site */

.vevent {


    .card {
        border: 0;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
        width: 100px;
    }

    .card-header {
        border: 0;
    }

    .event_ical img {
        vertical-align: baseline;
        height: 1em;
        width: auto;
        color: #007bb3;
    }
    .cal_date {
        float:left;
        vertical-align:top;
        text-align: center;
        // margin: 42px 1.5em 0.5em 0;
        margin-top: 8px;
        background-color:white;
        border-radius:0.5em;
        /*box-shadow: 0.5em 0.5em 0.4em black;*/
        min-width:4.4em;
    }
    .cal_wkday {
        font-size:1em;
        font-weight:normal;
        padding-bottom:0.3em;
    }
    .cal_day {
        font-size:1.7em;
        padding-top:0.3em;
        font-weight: 700;
        color: #666;
    }
    .cal_month {
        font-size: 14px;
        line-height:1.3em;
        color: white;
        background: #007bb3;
    }

    .cal_wkday {
        color: #666;
        padding-bottom: 0;
    }

    .cal_info {
        margin-left: 2em;
        .tileHeadline {
            margin-top: 0;
        }
    }

    /* horizontal event metadata */
    ul.metadata { margin: 0.8em 0; }
    ul.horizontal { padding: 0; }
    ul.horizontal li {
        display: inline-block;
        line-height: 1.5em;
        list-style-image: none;
        list-style-type: none;
    }
    .horizontal.metadata {
        li:after { content: " \2014 "; }
        li:last-child:after { content: ""; }
        .category span:after { content: ","; }
        .category span:last-child:after { content: ""; }
    }

    .timezone {
        display:block;
        margin-top:0.5em;
        padding:0.2em 0;
        background-color:#ffe;
        color:#354;
        width:100%;
    }

    .timerange, .timezone {
        font-size:85%;
        line-height:1em;
        white-space:nowrap;
    }

    .datedisplay {
        line-height:1.5em;
        margin-bottom:0.2em;
        /*font-weight:bold;*/
        font-size:100%;
    }

    abbr.dtstart {
        /*font-weight:bold;*/
    }
}
/* END COMMON EVENT STYLES */


/* BEGIN EVENT LISTING STYLES */
.template-event_listing {
    .documentFirstHeading {
        position: relative;
    }
    .documentFirstHeading nav {
        font-size: 14px;
        position: absolute;
        right: 0;
        bottom: 13px;

        ul {
            margin:0;
            list-style-type:none;
        }
    }

    ul.mode_selector {
        display: block;
        margin-left: 0;
        list-style-type: none;
    }
    ul.mode_selector li {
        display: inline;
    }
    nav ul.mode_selector li a {
        display: inline-block;
        margin-left: 0.5em;
    }
    nav ul.mode_selector li a:hover,
    nav ul.mode_selector li a:focus {
        text-decoration: underline;
        border-bottom: 0;
    }
}

/* END EVENT LISTING STYLES */
